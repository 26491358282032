import { useState, useEffect } from 'react';
import axios from 'axios';
import azkSelect from "_azkatech/azkSelect"
import { useNavigate, useParams } from 'react-router';
import { KTCard, KTIcon } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper';
import * as authHelper from 'app/modules/auth/core/AuthHelpers'
import { Col, Form, Row } from 'react-bootstrap';
import { AuthModel } from 'app/modules/auth';
import { GetEmployeeByUserId } from 'app/modules/components/employeeDetail/_requests';
import { TravelRequest } from './model';
import TransactionAPPViewform from './app-view';
import moment from 'moment';
import { AZKHelper } from 'app/modules/components/azkHelper';

const TransactionAppListView = () => {
    const { id } = useParams();
    const [employeeId, setEmployeeId] = useState<number>()
    const navigate = useNavigate()
    const MODEL_NAME = "az.trn.travel.request"

    const [selectedTravelRequest, setSelectedTravelRequest] = useState<TravelRequest>();
    const [currectEmployee, setCurrectEmployee] = useState<number>();

    const [dateFrom, setDateFrom] = useState<any>();
    const [dateTo, setDateTo] = useState<any>();
    const [handleChangeSelection, setHandleChangeSelection] = useState<boolean>(true);

    const TRN_list_LOCAL_STORAGE_FILTER_KEY = 'azk-trn-adjustment-list-filter-hr-lite'
    const fields = "'id', 'name', 'employee_id', 'azadea_employee_name', 'create_date', 'can_review', 'employee_name', 'x_state', 'state', 'x_state_view', 'restarted'"

    const [myRequest, setMyRequest] = useState<boolean>(true);
    const [shopUser, setShopUser] = useState<boolean>(true);
    const [isManger, setIsManger] = useState<boolean>(false);

    const updateListDataDomain = (currectEmployee_id, employee_id, state, myRequest, dateFrom, dateTo, isManger, shopUSer) => {
        let filter = {}
        let conds: string[] = [];
        if (currectEmployee_id !== '' && currectEmployee_id && currectEmployee_id != 'undefined') {
            conds.push(`('employee_id','=', ${currectEmployee_id})`)
            if (handleChangeSelection) filter['employee_id'] = currectEmployee_id
        }
        if (state !== '' && state && state != 'undefined') {
            conds.push(`('x_state','ilike', '${state}')`)
            if (handleChangeSelection) filter['x_state'] = state
        }
        if (employee_id !== '' && employee_id && employee_id != 'undefined') {
            conds.push(`('employee_id','=', ${employee_id})`)
            if (handleChangeSelection) filter['employee_id'] = employee_id
        }
        if (dateFrom && moment(dateFrom).isValid()) {
            conds.push(`('create_date','>=', '${moment(dateFrom).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)}')`)
            if (handleChangeSelection) filter['date_from'] = dateFrom
        }
        if (dateTo && moment(dateTo).isValid()) {
            conds.push(`('create_date','<=', '${moment(dateTo).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)}')`)
            if (handleChangeSelection) filter['date_to'] = dateTo
        }
        if (!isManger || myRequest) {
            conds.push(`('employee_id','=', ${currectEmployee_id})`)
        } else {
            if (isManger && shopUSer) {
                conds.push(`'|', ('employee_id.parent_id','=', ${currectEmployee_id}), ('employee_id.super_parent_id','=', ${currectEmployee_id})`)
            }
            if (isManger && !shopUSer) {
                conds.push(`('employee_id.parent_id','=', ${currectEmployee_id})`)
            }
        }
        const lsValue = JSON.stringify(filter)
        if (handleChangeSelection) localStorage.setItem(TRN_list_LOCAL_STORAGE_FILTER_KEY, lsValue)
        return conds.join(',');
    }

    const [data, setData] = useState<TravelRequest[]>();
    const [pennding, setPending] = useState<boolean>(false);

    const viewResult = async (dataDomain) => {
        document.body.classList.add('page-loading')
        setPending(true);
        let remoteData = []
        try {
            const result = await axios.get(`${API_URL}/${MODEL_NAME}?filters=[${dataDomain}]&include_fields=[${fields}]&order=name DESC`); //&offset=${offset}&limit=${perPage}
            remoteData = result.data?.results ? result.data.results : []
            document.body.classList.remove('page-loading')
        } catch (error) {
            console.error(error);
            document.body.classList.remove('page-loading')
        }
        if (remoteData)
            setData(remoteData);
        setPending(false);
    }

    // #endregion Transfer List

    // #region State Dropdown

    const fetchUser = async () => {
        const from_date = moment().startOf('month').format(AZKHelper.BACKEND_DATE_FORMAT)
        const to_date = moment().startOf('month').add(1, 'month').format(AZKHelper.BACKEND_DATE_FORMAT)
        setDateFrom(from_date)
        setDateTo(to_date)
        let _auth = authHelper.getAuth()
        if (!_auth) {
            const auth = localStorage.getItem('azk-auth-hr-lite')
            if (auth) {
                _auth = JSON.parse(auth) as AuthModel
            }
        }
        GetEmployeeByUserId(_auth?.uid).then(async response => {
            if (response && response.is_manager_fr && response.is_manager_fr !== null) {
                setIsManger(true)
                setCurrectEmployee(response.id)
            } else {
                setIsManger(false)
                setCurrectEmployee(response.id)
            }
            if (response.is_back_office_fr && response.is_back_office_fr !== null) setShopUser(!response?.is_back_office_fr)
            // if (response.is_manager_fr) setMangerUser(response?.is_manager_fr)
            const filterVals: string | null = localStorage.getItem(TRN_list_LOCAL_STORAGE_FILTER_KEY)
            if (filterVals) {
                const jsonObj = JSON.parse(filterVals)
                // setShowFilters(jsonObj['showFilters'])
                if (jsonObj['employee_id']) await setEmployeeId(jsonObj['employee_id'])
                if (jsonObj['date_from']) await setDateFrom(jsonObj['date_from'])
                if (jsonObj['date_to']) await setDateTo(jsonObj['date_to'])
                // if (jsonObj['x_state']) await setState(jsonObj['x_state'])
                setTimeout(() => {
                    viewResult(updateListDataDomain(response.id, undefined, jsonObj['x_state'], myRequest, dateFrom, dateTo, response.is_manager_fr, response.is_back_office_fr));
                }, 500)
            } else {
                viewResult(updateListDataDomain(response.id, undefined, undefined, undefined, from_date, to_date, response.is_manager_fr, response.is_back_office_fr));
            }
            setTimeout(() => {
                setHandleChangeSelection(true)
            }, 1000)
        })
    }

    useEffect(() => {
        document.body.classList.remove('page-loading')
        fetchUser();
        if(id){
            let transaction:TravelRequest = {
                line_ids: []
            }
            transaction['id'] = parseInt(id)
            setSelectedTravelRequest(transaction)
        }
    }, []);

    // #region status Dropdown
    const [az_state, setState] = useState('');

    const az_state_select = azkSelect({
        compID: "az_state_select",
        modelName: "az.transaction.status",
        valueField: "technical_name",
        dataDomain: `('basic_status', '=', 'true')`,
        titleField: "name",
        allowEmpty: false,
        isSearchable: false,
        defaultValue: az_state,
        onChange: (value) => {
            setState(value)
        },
    });
    // #endregion status Dropdown

    return (
        <>
            <Row className="m-0 p-0">

                <KTCard className='rounded-0 custom-input-container'>
                    <div className='card-body p-2'>
                        <div className='row m-0'>
                            <h6 className='py-2'>
                                View, edit, and add to keep track of your Travel requests effortlessly.
                            </h6>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-6'>
                                {/* begin::Label */}
                                <label className='form-label required'>From</label>
                                <Form.Control
                                    className='p-1'
                                    type='date'
                                    onChange={(event) => {
                                        setDateFrom(moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                    }}
                                    defaultValue={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    value={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    max={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                />
                            </div>

                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-6' >
                                {/* begin::Label */}
                                <label className='form-label required'>To</label>
                                <Form.Control
                                    className='p-1'
                                    type='date'
                                    onChange={(event) => {
                                        setDateTo(moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                    }}
                                    defaultValue={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    value={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    min={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                />
                            </div>

                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-6'>
                                {/* begin::Label */}
                                <label className='form-label'>Status</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_state_select}
                                </>)}
                                {/* end::Input */}
                            </div>

                            <div className='fv-row col-lg-12 col-md-12 col-12'>
                                <button type='button' className='btn btn-dark rounded-0 btn-sm' onClick={() => {
                                    viewResult(updateListDataDomain(currectEmployee, employeeId, az_state, myRequest, dateFrom, dateTo, isManger, shopUser))
                                }}>
                                    View Result
                                </button>
                            </div>

                        </div>
                    </div>
                </KTCard>
                <hr className="hr m-0" />
                {/* <KTCard className={`rounded-0 py-2 px-0 m-0 custom-KTCard-table ${myTravelRequests? '':'custom-KTCard-table-with-check'}`} > */}
                <KTCard className={`rounded-0 py-2 px-2 m-0 custom-KTCard-table }`} >
                    <div className='row m-0 d-flex justify-content-end p-0 m-0'>
                        <div className='d-flex justify-content-end p-0 m-0'>
                            <button type='button' className='btn btn-outline btn-color-dark btn-sm rounded-0' onClick={() => navigate(`/app/self-service/travel-request/new`, { state: {}, replace: true })}>
                                New
                                <KTIcon iconName='plus' className='fs-2' />
                            </button>
                        </div>
                    </div>
                </KTCard>
            </Row>
            <Row className='m-0 px-3 py-2' style={{ backgroundColor: '#D9D9D9', color: '#838383' }}>
                <Col lg="7" sm="6" className="adjustment-header-label col-6">Details</Col>
                <Col lg="4" sm="4" className="adjustment-header-label col-4">Status</Col>
                <Col lg="1" sm="2" className="adjustment-header-label col-2"></Col>
            </Row>
            {data && data.length > 0 &&
                data.map(request => {
                    return (<>
                        <Row className='m-0 px-3 py-2'>
                            <Col lg="7" sm="6" className="col-6"><span className='adjustment-form-label-value-bold '><b>business travel Request {request.name}</b> </span><br />
                                <span className='adjustment-form-label-value '>Date: {moment(request.create_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}</span></Col>
                            <Col lg="4" sm="4" className="adjustment-form-label-value col-4 d-flex align-items-center">{request.x_state_view}</Col>
                            <Col lg="1" sm="2" className='col-2'>
                                <button title='view-details' name='view-details' className='btn btn-outline btn-color-dark btn-sm border-0' onClick={() => {
                                    navigate(`/app/self-service/travel-request/${request.id}/view`, { state: {}, replace: true });
                                    // setSelectedTravelRequest(request)
                                }}>
                                    <i className="bi bi-arrow-right fs-1 fw-bolder"></i>
                                </button>
                            </Col>
                        </Row>
                        <hr className="hr m-0" />
                    </>)
                })
            }
            {/* <div>
                {selectedTravelRequest && <TransactionAPPViewform requestId={selectedTravelRequest.id} closeModal={function (): {} {
                    setSelectedTravelRequest(undefined);
                    viewResult(updateListDataDomain(currectEmployee, employeeId, az_state, myRequest, dateFrom, dateTo, isManger, shopUser));
                    return false;
                }}></TransactionAPPViewform>}
            </div> */}
        </>)
}

export default TransactionAppListView;