import AzkSelect from "_azkatech/azkSelect";
import { EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios from "axios";
import * as Yup from 'yup'
import { SelfService, SelfServiceTypeFields, initialTransfaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import { CreateSelfService, UpdateSelfService, MODEL_NAME, DeleteSelfService } from "./_requests";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import { ID, isNotEmpty } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import {  checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import {  GetEmployeeByUserId } from "app/modules/components/employeeDetail/_requests";
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from "app/modules/auth";
import AzkazkField from "_azkatech/azkField";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
    lookup_code: Yup.string().notRequired(),
    service_type_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('SelfService type is required'),
    fields: Yup.array().of(
        Yup.object().shape({
            id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value).required('Field id is required'),
            email: Yup.string().required('Field id is required'),
        })
    )
})

const TransactionAPPform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<SelfService>>(null);
    const [selfServiceTypeDomain, setSelfServiceTypeDomain] = useState('');
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();

    const [formFields, setFormFields] = useState<SelfServiceTypeFields[]>();

    const handleSelfServiceTypeChange = (typeId) => {
        if (typeId !== '' && typeId) {
            axios.get(`${API_URL}/az.self.service.type?filters=[('id', '=', ${typeId ? typeId : '0'})]&include_fields=['name',('fields', [('id' ,'name', 'type', 'required', 'model', 'data_set')])]`).then((result: any) => {
                if (result.data && result.data.results && result.data.count > 0 && result.data.results[0] !== null) {
                    setFormFields(result.data.results[0]['fields'])
                }
            });
        } else{
            setFormFields([])
        }
    }
    const handleEmployeeChange = async function (employeeId): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeByUserId(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    setEmployee(response)
                    setSelfServiceTypeDomain(tempEmployee.az_country_id ? `('country_id','=', ${tempEmployee.az_country_id?.id})` : '');
                    // handleJobChange(false)
                    formRef.current?.setValues({
                        employee_id: tempEmployee.id,
                        az_country_id: tempEmployee.az_country_id?.id,
                        az_company_id: tempEmployee.az_company_id?.id,
                        branch_id: tempEmployee.branch_id?.id,
                        brand_id: tempEmployee.brand_id?.id,
                        grade_id: tempEmployee.grade_id?.id,
                        section_id: tempEmployee.section_id?.id,
                        job_id: tempEmployee.job_id?.id,
                        // time_set: tempEmployee.time_set,
                        // ---------------------------------------------------
                        self_service_field: []
                    })
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                time_set: undefined,
                self_service_field: []
            })
        }
        return true
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/app/self-service/service/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "center",
                        // title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        // toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/app/self-service/service/`, { state: {}, replace: true });
                Swal.fire({
                    position: "center",
                    // title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    // toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    useEffect(() => {
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        checkPermission('create');
        setCanEdit(true)
        document.body.classList.remove('page-loading')
        let lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (lsValue) {
            const auth: AuthModel = JSON.parse(lsValue) as AuthModel
            if (auth && auth.uid) {
                handleEmployeeChange(auth.uid)
            }
        }
    }, [id])


    const cancel = () => {
        navigate(`/app/self-service/service/`, { state: {}, replace: true });
    }

    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        document.body.classList.remove('page-loading')
        navigate(`/app/self-service/service/`, { state: {}, replace: true })
        Swal.fire({
            position: "center",
            // title: 'Success!',
            text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
            icon: "success",
            showConfirmButton: false,
            timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
            // toast: true,
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            timerProgressBar: true
        })
    }

    const validateFields = async () => {
        let canSave = true
        const fieldsValue: any[] = []
        let fields: SelfServiceTypeFields[] = []
        if (formFields) fields = formFields
        if (!formFields) return canSave
        // replace the element object
        await fields.map((element) => {
            if (!element.valid && element.required) {
                canSave = false
            } else if (element.value) {
                fieldsValue.push(
                    { 'key': element.name, 'field_id': element.id, 'value': element.value })
            }
        });
        return { canSave, fieldsValue }
    }
    const onSubmitHandler = async (values: SelfService, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            validateFields().then(async result => {
                if (result['canSave']) {
                    values['self_service_field'] = result['fieldsValue']
                    document.body.classList.add('page-loading')
                    if (isNotEmpty(values.id)) {
                        await UpdateSelfService(values).then(data => {
                            if (id) handleResultOfsubmit(parseInt(id), true)
                        })
                    } else {
                        await CreateSelfService(values).then(data => {
                            handleResultOfsubmit(undefined, false)
                        })
                    }
                }
            })

        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }

    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransfaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}
                                    <Form onSubmit={handleSubmit} noValidate>
                                        {employee && (
                                            <>
                                                <Row className="card">
                                                    <fieldset disabled={!canEdit || readOnly}>
                                                        <Row className="m-0 my-2">
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Select your required service
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_service_type_id_select"
                                                                        modelName="az.self.service.type"
                                                                        valueField="id"
                                                                        titleField="name"
                                                                        dataDomain={selfServiceTypeDomain}
                                                                        isMultiple={false}
                                                                        allowEmpty={true}
                                                                        fieldName='service_type_id'
                                                                        disabled={!canEdit}
                                                                        onChange={(value) => {
                                                                            if (value !== null){
                                                                            setFieldValue('service_type_id', parseInt(value))
                                                                            handleSelfServiceTypeChange(parseInt(value))
                                                                            } else {
                                                                                setFieldValue('service_type_id', undefined)
                                                                                handleSelfServiceTypeChange(undefined)
                                                                            }
                                                                        }}
                                                                        defaultValue={values.service_type_id} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='service_type_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>

                                                        </Row>
                                                        <Row className="m-0 my-2">
                                                            {formFields && formFields.length > 0 &&
                                                                formFields.map(field => {
                                                                    return <> <AzkazkField inputType={field.type} lableTitle={field.name} modelName={field.model ? field.model : ''} compID={""} required={field.required}
                                                                        onChange={(value, valid) => {
                                                                            field.value = value
                                                                            field.valid = valid
                                                                        }}
                                                                        isSubmit={isSubmitting}
                                                                        appView={true}
                                                                        data_set={field.data_set ? field.data_set : ''}></AzkazkField></>
                                                                })
                                                            }
                                                        </Row>
                                                    </fieldset>

                                                    {/* begin::Actions */}
                                                        <Row className="m-0 my-2">
                                                    <div className='py-5 d-flex justify-content-between col-12'>
                                                        {canEdit &&
                                                            <button
                                                                title="Submit for approval"
                                                                type='submit'
                                                                className='btn btn-dark rounded-0 btn-sm col-5'
                                                                disabled={!canEdit}>
                                                                <span className='indicator-label'>Submit</span>
                                                            </button>
                                                        }
                                                        <button
                                                            title="Discard"
                                                            type='reset'
                                                            onClick={() => cancel()}
                                                            className='btn btn-dark rounded-0 btn-sm col-5'
                                                            data-kt-users-modal-action='cancel'
                                                        >
                                                            <span className='indicator-label'>Discard</span>

                                                        </button>
                                                    </div>
                                                    </Row>
                                                    {/* end::Actions */}
                                                </Row>
                                            </>)}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
            </Row>
        </>
    )
}

export default TransactionAPPform;
