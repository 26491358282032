import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper'
import { EmpoyeeModel } from './employeeDetail'
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'hr.employee'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS_FULL = `'id','name', 'azadea_employee_name', 'as_effective_date', 'salary', 'time_set', 'has_running_transaction', 'running_transaction',('grade_id',('id','grade_name')), ('branch_id',('id','name', 'organization_name')), ('job_id',('id','name')), ('brand_id',('id','name', 'brand_code')), ('az_country_id',('id','name')), ('az_company_id',('id','name')), ('payroll_id',('id','payroll_name')), ('section_id',('id','section')), 'azadea_id', 'parent_id', ('employment_category_id', ('id', 'name', 'code')), 'start_date'`
const INCLUDE_FIELDS = `'id','name', 'azadea_employee_name', 'as_effective_date', 'time_set', 'has_running_transaction', 'running_transaction',('grade_id',('id','grade_name')), ('branch_id',('id','name', 'organization_name')), ('job_id',('id','name')), ('brand_id',('id','name', 'brand_code')), ('az_country_id',('id','name')), ('az_company_id',('id','name')), ('payroll_id',('id','payroll_name')), ('section_id',('id','section')), 'azadea_id', 'parent_id', ('employment_category_id', ('id', 'name', 'code')), 'start_date', 'is_manager', 'is_back_office', 'is_manager_fr', 'is_back_office_fr', 'is_supper_access', 'name_in_passport', 'passport_number', 'passport_expiry', 'birthday', 'mobile_phone'`
const INCLUDE_FIELDS_Attendance = `'id','name', 'azadea_employee_name', 'as_effective_date', 'time_set', 'has_running_transaction', 'running_transaction',('grade_id',('id','grade_name')), ('branch_id',('id','name', 'organization_name')), ('job_id',('id','name')), ('brand_id',('id','name', 'brand_code')), ('az_country_id',('id','name')), ('az_company_id',('id','name')), ('payroll_id',('id','payroll_name')), ('section_id',('id','section')), 'azadea_id', ('employment_category_id', ('id', 'name', 'code')), 'start_date', 'is_manager', 'is_back_office', 'is_manager_fr', 'is_back_office_fr', 'is_supper_access', 'name_in_passport', 'passport_number', 'passport_expiry', 'birthday', 'mobile_phone', 'allwoed_back_number_of_day', 'allwoed_travel_request', 'allowed_time_diff'`

const GetEmployeeById = (id: ID): Promise<EmpoyeeModel> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id}), ('active', 'in', [True, False])]&include_fields=[${INCLUDE_FIELDS_FULL}]`)
    .then((response: AxiosResponse<Response<EmpoyeeModel>>) => response.data)
    .then((response: Response<EmpoyeeModel>) => FixServerData(response['results'][0]))
}
const GetEmployeeByIdNoSalary = (id: ID): Promise<EmpoyeeModel> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id}), ('active', 'in', [True, False])]&include_fields=[${INCLUDE_FIELDS_Attendance}]`)
    .then((response: AxiosResponse<Response<EmpoyeeModel>>) => response.data)
    .then((response: Response<EmpoyeeModel>) => FixServerData(response['results'][0]))
}
const GetEmployeeByIdAttendance = (id: ID): Promise<EmpoyeeModel> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id}), ('active', 'in', [True, False])]&include_fields=[${INCLUDE_FIELDS_Attendance}]`)
    .then((response: AxiosResponse<Response<EmpoyeeModel>>) => response.data)
    .then((response: Response<EmpoyeeModel>) => FixServerData(response['results'][0]))
}
const GetEmployeeByUserId = (id: ID): Promise<EmpoyeeModel> => {
  return axios
    .get(`${URL}?filters=[('user_id','=',${id}), ('active', 'in', [True, False])]&include_fields=[${INCLUDE_FIELDS_Attendance}]&self_serverice=True`)
    .then((response: AxiosResponse<Response<EmpoyeeModel>>) => response.data)
    .then((response: Response<EmpoyeeModel>) => {
      if (response['results'] && response['results'][0] && response['results'][0]['is_manager'] === null) response['results'][0]['is_manager'] = false
      if (response['results'] && response['results'][0] && response['results'][0]['is_back_office'] === null) response['results'][0]['is_back_office'] = false
      return response['results'][0]
    })
}
export { GetEmployeeById, GetEmployeeByIdNoSalary, GetEmployeeByIdAttendance, GetEmployeeByUserId}

