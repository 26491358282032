import AzkSelect from "_azkatech/azkSelect";
import { EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios from "axios";
import * as Yup from 'yup'
import { AttendanceAdjustment, AttendanceAdjustmentLine, initialTransfaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, FieldArray, Formik, FormikProps, FormikValues } from 'formik'
import { CreateAttendanceAdjustment, UpdateAttendanceAdjustment, GetAttendanceAdjustmentById, MODEL_NAME } from "./_requests";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import { ID, isNotEmpty } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import { GetEmployeeByUserId } from "app/modules/components/employeeDetail/_requests";
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from "app/modules/auth";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";

type Props = {
}
let number_of_back_day = 0
let allowed_time_diff = 0
let is_submitting_form = false
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
    adjustment_reason_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Attendance Adjustment reason is required'),
    line_ids: Yup.array().of(
        Yup.object().shape({
            date: Yup.date().transform((value) => !moment(value).isValid() ? undefined : value).required('Date is required')
                .test('min', (min) => { return `Date must be equal or after ${moment().clone().subtract(number_of_back_day, 'day').format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}` }, function (value) {
                    const start_date = moment().clone().subtract(number_of_back_day, 'day').startOf('day')
                    return !start_date.isAfter(moment(value));
                })
                .max(new Date(), 'Date must be equal or before today`s date'),
            hour: Yup.string().required('Field id is required'),
            min: Yup.string().required('Field id is required'),
            adjustment_type: Yup.string().required('Field id is required'),
        })
    )
})

const TransactionAPPform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<AttendanceAdjustment>>(null);
    const [attendanceAdjustmentTypeDomain, setAttendanceAdjustmentTypeDomain] = useState('');
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();

    const [formFields, setFormFields] = useState<AttendanceAdjustmentLine[]>();

    const handleAttendanceAdjustmentTypeChange = (typeId) => {
        if (typeId !== '' && typeId) {
            axios.get(`${API_URL}/az.attendance.adjust.reason?filters=[('id', '=', ${typeId ? typeId : '0'})]&include_fields=['name',('fields', [('id' ,'name', 'type', 'required', 'model', 'data_set')])]`).then((result: any) => {
                if (result.data && result.data.results && result.data.count > 0 && result.data.results[0] !== null) {
                    setFormFields(result.data.results[0]['fields'])
                }
            });
        } else {
            setFormFields([])
        }
    }
    const handleEmployeeChange = async function (employeeId): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeByUserId(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    setEmployee(response)
                    const start_of_the_month = moment().startOf('month')
                    const today = moment().clone()
                    if (tempEmployee.allwoed_back_number_of_day === -1 || tempEmployee.allwoed_back_number_of_day === null) {
                        number_of_back_day = moment().diff(start_of_the_month, 'day')
                    } else {
                        if (today.subtract(tempEmployee.allwoed_back_number_of_day, 'day').isBefore(start_of_the_month)) {
                            number_of_back_day = moment().diff(start_of_the_month, 'day')
                        } else {
                            number_of_back_day = tempEmployee.allwoed_back_number_of_day
                        }
                    }
                    allowed_time_diff = tempEmployee.allowed_time_diff
                    formRef.current?.setValues({
                        employee_id: tempEmployee.id,
                        az_country_id: tempEmployee.az_country_id?.id,
                        az_company_id: tempEmployee.az_company_id?.id,
                        branch_id: tempEmployee.branch_id?.id,
                        brand_id: tempEmployee.brand_id?.id,
                        grade_id: tempEmployee.grade_id?.id,
                        section_id: tempEmployee.section_id?.id,
                        job_id: tempEmployee.job_id?.id,
                        // time_set: tempEmployee.time_set,
                        // ---------------------------------------------------
                        line_ids: [
                            {
                                date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT), hour: undefined, min: undefined, adjustment_type: undefined,
                                id: 0
                            },]
                    })
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                time_set: undefined,
                adjustment_reason_id: undefined,
                line_ids: [
                    {
                        date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT), hour: undefined, min: undefined, adjustment_type: undefined,
                        id: 0
                    },]
            })
        }
        return true
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/app/self-service/attendance/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "center",
                        // title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        // toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/app/self-service/attendance/`, { state: {}, replace: true });
                Swal.fire({
                    position: "center",
                    // title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    // toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    useEffect(() => {
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        checkPermission('create');
        setCanEdit(true)
        document.body.classList.remove('page-loading')
        let lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (lsValue) {
            const auth: AuthModel = JSON.parse(lsValue) as AuthModel
            if (auth && auth.uid) {
                handleEmployeeChange(auth.uid)
            }
        }
    }, [id])


    const cancel = () => {
        navigate(`/app/self-service/attendance/`, { state: {}, replace: true });
    }

    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        document.body.classList.remove('page-loading')
        navigate(`/app/self-service/attendance/`, { state: {}, replace: true })
        Swal.fire({
            position: "center",
            // title: 'Success!',
            text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
            icon: "success",
            showConfirmButton: false,
            timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
            // toast: true,
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            timerProgressBar: true
        })
    }


    const validateFields = async () => {
        let canSave = true
        let fields: AttendanceAdjustmentLine[] = []
        fields = formRef?.current?.getFieldProps('line_ids').value
        // if (!fields) return false
        // replace the element object
        const lines: string[] = []
        let dates: any[] = []
        await fields.map((element) => {
            const line_text = moment(element.date).format(AZKHelper.FRONTEND_DATE_FORMAT) + element.adjustment_type + element.hour + element.min;
            if (lines.includes(line_text)) canSave = false
            lines.push(line_text)
            const line_time = moment(element.date)
            if (element.hour) line_time.hour(parseInt(element.hour))
            if (element.min) line_time.minute(parseInt(element.min))
            line_time.second(0)
            line_time.millisecond(0)
            dates.push(line_time)
        });
        const sorted_dates = dates.sort((a, b) => { return moment(a).diff(moment(b)) })
        await sorted_dates.map((date_line, index) => {
            if (index > 0) {
                if (date_line.diff(sorted_dates[index - 1], 'minute') <= allowed_time_diff) {
                    canSave = false
                }
            }
        });
        return { canSave }
    }

    const onSubmitHandler = async (values: AttendanceAdjustment, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid && is_submitting_form) {
            validateFields().then(async result => {
                if (result['canSave']) {
                    // values['self_service_field'] = result['fieldsValue']
                    document.body.classList.add('page-loading')
                    if (isNotEmpty(values.id)) {
                        await UpdateAttendanceAdjustment(values).then(data => {
                            if (id) handleResultOfsubmit(parseInt(id), true)
                        })
                    } else {
                        await CreateAttendanceAdjustment(values).then(data => {
                            handleResultOfsubmit(undefined, false)
                        })
                    }
                } else {
                    Swal.fire({
                        position: "center",
                        title: 'Error!',
                        text: 'Duplicate attandance line!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                }
            })
        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }

    const loadTypeOptions = async (search, loadedOptions) => {
        const options: any[] = []
        options.push({ value: 'in', label: 'In' })
        options.push({ value: 'out', label: 'Out' })
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };


    const loadHourOptions = async (search, loadedOptions) => {
        const options: any[] = []
        for (let index = 0; index < 24; index++) {
            options.push({ value: index, label: index.toString().padStart(2, '0') })
        }
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };


    const loadMinutesOptions = async (search, loadedOptions) => {
        const options: any[] = []
        for (let index = 0; index < 60; index += 1) {
            options.push({ value: index, label: index.toString().padStart(2, '0') })
        }
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };


    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransfaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting, validateForm }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}
                                    <Form onSubmit={handleSubmit} noValidate>
                                        {employee && (
                                            <>
                                                <Row className="card">
                                                    <h6>
                                                        Enter your new attendance details.
                                                    </h6>
                                                    <fieldset disabled={!canEdit || readOnly} className="p-0">
                                                        <Row className="m-0 my-2">
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        select reason
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_adjustment_reason_id_select"
                                                                        modelName="az.attendance.adjust.reason"
                                                                        valueField="id"
                                                                        titleField="name"
                                                                        dataDomain={attendanceAdjustmentTypeDomain}
                                                                        isMultiple={false}
                                                                        allowEmpty={true}
                                                                        fieldName='adjustment_reason_id'
                                                                        disabled={!canEdit}
                                                                        onChange={(value) => {
                                                                            if (value !== null) {
                                                                                setFieldValue('adjustment_reason_id', parseInt(value))
                                                                            } else {
                                                                                setFieldValue('adjustment_reason_id', undefined)
                                                                            }
                                                                        }}
                                                                        defaultValue={values.adjustment_reason_id} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='adjustment_reason_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>

                                                        </Row>
                                                        <Row className="m-0 my-2">
                                                            <FieldArray name="line_ids">
                                                                {({ push, remove }) => (
                                                                    <>
                                                                        {values.line_ids.map((line, index) => (
                                                                            <>
                                                                                <Row className="m-0 my-2 py-4" style={{ backgroundColor: '#F6F6F6' }}>
                                                                                    <Col lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-between">
                                                                                        <h6>
                                                                                            Attendance Entry {index + 1} :
                                                                                        </h6>
                                                                                        {(index > 0 || values.line_ids.length > 1) &&
                                                                                            < button
                                                                                                name={`line_ids[${index}].min` + 'delete_line'}
                                                                                                title="delete"
                                                                                                type="reset"
                                                                                                onClick={() => { remove(index) }}
                                                                                                className='p-0 btn btn-outline-dark bg-transparent rounded'
                                                                                            >
                                                                                                <i className="bi bi-trash" aria-hidden="true"></i>
                                                                                            </button>
                                                                                        }
                                                                                    </Col>
                                                                                    <Col lg="4" md="12" sm="12" xs="12">
                                                                                        <Form.Group className='mb-2'>
                                                                                            <Form.Label className='required'>
                                                                                                Date
                                                                                            </Form.Label>
                                                                                            <Form.Control
                                                                                                className='p-1 col-12 rounded-0'
                                                                                                type="date"
                                                                                                id={`line_ids[${index}].date`}
                                                                                                name={`line_ids[${index}].date`}
                                                                                                onChange={(value) => {
                                                                                                    line.date = moment(value.target.value).format(AZKHelper.FRONTEND_DATE_FORMAT)
                                                                                                    values.line_ids[index].date = moment(value.target.value).format(AZKHelper.FRONTEND_DATE_FORMAT)
                                                                                                    formRef?.current?.validateForm()
                                                                                                }}
                                                                                                min={moment().subtract(number_of_back_day, 'day').format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                                                max={moment().format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                                                value={moment(values.line_ids[index].date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                                            />
                                                                                            <div className='text-danger'>
                                                                                                <ErrorMessage name={`line_ids[${index}].date`} component="div" />
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                    </Col>

                                                                                    <Col lg="4" md="5" sm="5" xs="5">
                                                                                        <Form.Group className='mb-2'>
                                                                                            <Form.Label className='required'>
                                                                                                Type
                                                                                            </Form.Label>
                                                                                            <AsyncPaginate className='rounded-0 col-12'
                                                                                                placeholder="Select an option"
                                                                                                loadOptions={loadTypeOptions}
                                                                                                onChange={(value) => {
                                                                                                    line.adjustment_type = value?.value
                                                                                                    values.line_ids[index].adjustment_type = value?.value
                                                                                                    formRef?.current?.validateForm()
                                                                                                }}
                                                                                                defaultValue={{ value: values.line_ids[index].adjustment_type, label: values.line_ids[index].adjustment_type }}
                                                                                                value={{ value: values.line_ids[index].adjustment_type, label: values.line_ids[index].adjustment_type }}
                                                                                                id={`line_ids[${index}].adjustment_type`} name={`line_ids[${index}].adjustment_type`}
                                                                                            />
                                                                                            <div className='text-danger'>
                                                                                                <ErrorMessage name={`line_ids[${index}].adjustment_type`} component="div" />
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                    </Col>

                                                                                    <Col lg="4" md="1" sm="1" xs="1">
                                                                                    </Col>
                                                                                    <Col lg="4" md="3" sm="3" xs="3">
                                                                                        <Form.Group className='mb-2'>
                                                                                            <Form.Label className='required'>
                                                                                                Hours
                                                                                            </Form.Label>

                                                                                            <AsyncPaginate className='rounded-0 col-12'
                                                                                                placeholder="Select an option"
                                                                                                loadOptions={loadHourOptions}
                                                                                                onChange={(value) => {
                                                                                                    line.hour = value?.value
                                                                                                    values.line_ids[index].hour = value?.value
                                                                                                    formRef?.current?.validateForm()
                                                                                                }}
                                                                                                defaultValue={{ value: values.line_ids[index].hour, label: values.line_ids[index].hour }}
                                                                                                value={{ value: values.line_ids[index].hour, label: values.line_ids[index].hour }}
                                                                                                id={`line_ids[${index}].hour`} name={`line_ids[${index}].hour`} />
                                                                                            <div className='text-danger'>
                                                                                                <ErrorMessage name={`line_ids[${index}].hour`} component="div" />
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                    </Col>

                                                                                    <Col lg="4" md="3" sm="3" xs="3">
                                                                                        <Form.Group className='mb-2'>
                                                                                            <Form.Label className='required'>
                                                                                                Mins
                                                                                            </Form.Label>
                                                                                            <AsyncPaginate className='rounded-0 col-12'
                                                                                                placeholder="Select an option"
                                                                                                loadOptions={loadMinutesOptions}
                                                                                                onChange={(value) => {
                                                                                                    line.min = value?.value
                                                                                                    values.line_ids[index].min = value?.value
                                                                                                    formRef?.current?.validateForm()
                                                                                                }}
                                                                                                defaultValue={{ value: values.line_ids[index].min, label: values.line_ids[index].min }}
                                                                                                value={{ value: values.line_ids[index].min, label: values.line_ids[index].min }}
                                                                                                id={`line_ids[${index}].min`} name={`line_ids[${index}].min`} />
                                                                                            <div className='text-danger'>
                                                                                                <ErrorMessage name={`line_ids[${index}].min`} component="div" />
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                    </Col>

                                                                                </Row >
                                                                            </>
                                                                        ))}


                                                                        <div className='py-5 d-flex justify-content-between col-12'>
                                                                            <button
                                                                                title="Add entry"
                                                                                type='submit'
                                                                                className='btn btn-dark rounded-0 btn-sm col-3 mr-1'
                                                                                onClick={() => {
                                                                                    is_submitting_form = false
                                                                                    if (formRef?.current?.isValid) {
                                                                                        push({
                                                                                            date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT), hour: undefined, min: undefined, adjustment_type: '',
                                                                                            id: 0
                                                                                        })
                                                                                        formRef?.current?.getFieldHelpers('line_ids').setTouched(true)
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <span className='indicator-label'>NEW ENTRY</span>
                                                                            </button>
                                                                            {canEdit &&
                                                                                <button
                                                                                    title="Submit for approval"
                                                                                    type='submit'
                                                                                    className='btn btn-dark rounded-0 btn-sm col-3  mr-2'
                                                                                    disabled={!canEdit}
                                                                                    onClick={() => {
                                                                                        is_submitting_form = true
                                                                                    }}>
                                                                                    <span className='indicator-label'>Submit</span>
                                                                                </button>
                                                                            }
                                                                            <button
                                                                                title="Discard"
                                                                                type='reset'
                                                                                onClick={() => cancel()}
                                                                                className='btn btn-dark rounded-0 btn-sm col-3 ml-1'
                                                                                data-kt-users-modal-action='cancel'
                                                                            >
                                                                                <span className='indicator-label'>Discard</span>

                                                                            </button>
                                                                        </div>

                                                                    </>
                                                                )}
                                                            </FieldArray>
                                                        </Row>
                                                    </fieldset>

                                                    {/* begin::Actions */}
                                                    <Row className="m-0 my-2">
                                                    </Row>
                                                    {/* end::Actions */}
                                                </Row>
                                            </>)
                                        }
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col >
            </Row >
        </>
    )
}

export default TransactionAPPform;
