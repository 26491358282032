import AzkSelect from "_azkatech/azkSelect";
import { EmployeeDetail, EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios, { ResponseType } from "axios";
import * as Yup from 'yup'
import { ExitInterViewRequest, initialTransfaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, FieldArray, Formik, FormikProps, FormikValues } from 'formik'
import { UpdateExitInterViewRequest, GetExitInterViewRequestById, MODEL_NAME, DeleteExitInterViewRequest } from "./_requests";
import "react-datepicker/dist/react-datepicker.css";
import { AzkStatusRibbon } from "_azkatech/azkStatusRibbon";
import AzkActionButtons from "_azkatech/azkActionButtons";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ID, KTIcon, isNotEmpty } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import { GetEmployeeByIdNoSalary } from "app/modules/components/employeeDetail/_requests";
import generatePDF, { Margin, Resolution } from "react-to-pdf";
import ReactDOM from "react-dom";
import { data } from "jquery";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
})

const TransactionViewform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<ExitInterViewRequest>>(null);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<number>();

    const [backOffice, setBackOffice] = useState<boolean>(false);
    const [allowedButttons, setAllowedButttons] = useState<any[]>([]);
    const [direction, setDirection] = useState<string>('ltr');
    const [selectedLang, setselectedLang] = useState<string>('en');

    const handleEmployeeChange = async function (employeeId, ExitInterViewRequestData): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeByIdNoSalary(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    setEmployee(response)
                    formRef.current?.setValues({
                        employee_id: tempEmployee.id,
                        az_country_id: tempEmployee.az_country_id?.id,
                        az_company_id: tempEmployee.az_company_id?.id,
                        branch_id: tempEmployee.branch_id?.id,
                        brand_id: tempEmployee.brand_id?.id,
                        grade_id: tempEmployee.grade_id?.id,
                        section_id: tempEmployee.section_id?.id,
                        job_id: tempEmployee.job_id?.id,
                        // time_set: tempEmployee.time_set,
                        // ---------------------------------------------------
                        type_id: {}
                    })
                    if (ExitInterViewRequestData) {
                        pobulateFormData(ExitInterViewRequestData)
                    } else {
                    }
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
        }
        return true
    }


    const getTargetElement = () => document.getElementById('content-id');

    const generate_report = async (exitInterveiw: ExitInterViewRequest) => {
        let itmes: any = []
        let itmeAnswers: any = []
        let backOfficeSesction = false;
        if (exitInterveiw.type_id && exitInterveiw.type_id.back_office === true) {
            backOfficeSesction = true
        }
        await exitInterveiw?.sections?.map(async (section, index) => {

            if (section.section_id?.is_table_content !== true) {
                itmes.push(
                    <table style={{ marginTop: '20px' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <h4>{section.section_id?.title}</h4>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )
                await section.questions.map(async (question, index) => {
                    let answers: any = []
                    const data = question?.question_id?.answer_ids
                    if (data && data.length) {
                        if (question.question_id.type === AZKHelper.INPUT_RATE_FIELD) {
                            await data.map(async (answer, index) => {
                                answers.push(
                                    // <tr style={{ borderCollapse: 'collapse' }}>
                                    <td width="10%" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingLeft: '5px', paddingRight: '5px', fontSize: '16px' }}>
                                        <input type="checkbox" style={{ width: '25px' }} defaultChecked={question.answer_code === data[index]['code']} width={'25px'} height={'25px'} />&nbsp;{data[index]['code']}&nbsp;{data[index]['description']}
                                    </td>
                                    // </tr>
                                )
                            })
                        } else {
                            await data.map(async (answer, index) => {
                                if (index % 2 === 0) {
                                    answers.push(
                                        <tr style={{ borderCollapse: 'collapse' }}>
                                            <td width="48%" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingLeft: '5px', paddingRight: '5px', fontSize: '16px' }}>
                                                <input type="checkbox" style={{ width: '25px' }} defaultChecked={question.answer_code === data[index]['code']} width={'25px'} height={'25px'} />&nbsp;&nbsp;{data[index]['description']}
                                            </td>
                                            <td width="2%">
                                            </td>
                                            <td width="48%" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingLeft: '5px', paddingRight: '5px', fontSize: '16px' }}>
                                                <input type="checkbox" defaultChecked={question.answer_code === data[index + 1]['code']} width={'25px'} height={'25px'} />&nbsp;&nbsp;
                                                {data[index + 1]['description']}
                                            </td>
                                        </tr>
                                    )
                                }
                            })
                        }
                    } else {
                        answers.push(
                            <tr style={{ borderCollapse: 'collapse' }}>
                                <td width="100%" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingLeft: '5px', paddingRight: '5px', fontSize: '16px' }}>
                                    {question.answer}
                                </td>
                            </tr>
                        )
                    }
                    itmeAnswers[question.id] = answers
                })
                await section.questions.map(async (question, index) => {
                    itmes.push(
                        <>
                            <table width="100%" style={{ border: '1px solid black', borderCollapse: 'collapse', marginTop: '20px' }}>
                                <tbody>
                                    <tr style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                                        <td width="100%" colSpan={10} style={{ paddingLeft: '5px', paddingRight: '5px', fontSize: '16px' }}>
                                            <strong>
                                                {question.question_id?.name}
                                            </strong>
                                        </td>
                                    </tr>
                                    {itmeAnswers[question?.id]?.map(item => {
                                        return item
                                    })}
                                </tbody>
                            </table>
                            <table width="100%" style={{ borderCollapse: 'collapse' }}>
                                <tbody>
                                    {question.question_id?.comment_title &&
                                        <>
                                            <tr style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                                                <td width={'30%'} style={{ border: '1px solid black', borderCollapse: 'collapse', paddingLeft: '5px', paddingRight: '5px', fontSize: '16px' }}>
                                                    <strong>
                                                        {question.question_id?.comment_title}
                                                    </strong>
                                                </td>
                                                <td colSpan={2} width={'70%'} style={{ border: '1px solid black', borderCollapse: 'collapse', paddingLeft: '5px', paddingRight: '5px', fontSize: '16px' }}>
                                                    {question.comment}
                                                </td>
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            </table>
                        </>
                    )
                })
            } else {
                const firstQuestion = section.questions[0]?.question_id
                if (firstQuestion?.data_set && firstQuestion.type === AZKHelper.INPUT_DROPDOWN_FIELD) {
                    const lines: any = []
                    const headerLines: any = []

                    const dataHeader = firstQuestion?.answer_ids
                    if (dataHeader) {
                        dataHeader.map(async (answerItem, indexItem) => {
                            headerLines.push(<>
                                <td width="10%" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingLeft: '5px', paddingRight: '5px', fontSize: '16px' }}>
                                    {answerItem['description']}
                                </td>
                            </>)
                        })
                    }
                    lines.push(<>
                        <tr style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                            <td width="40%" colSpan={3} style={{ paddingLeft: '5px', paddingRight: '5px', fontSize: '16px' }}>

                            </td>
                            {headerLines?.map(item => {
                                return item
                            })}
                        </tr>
                    </>)
                    await section.questions.map(async (question, index) => {
                        let answers: any = []
                        const data = question?.question_id?.answer_ids
                        await data.map(async (answer, index) => {
                            answers.push(<>
                                <td width="10%" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingLeft: '5px', paddingRight: '5px', fontSize: '16px' }}>
                                    <input type="checkbox" defaultChecked={question.answer_code === data[index]['code']} width={'25px'} height={'25px'} />
                                </td>
                            </>
                            )
                        })
                        itmeAnswers[question.id] = answers
                        lines.push(<>
                            <tr style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                                <td width="40%" colSpan={3} style={{ paddingLeft: '5px', paddingRight: '5px', fontSize: '16px' }}>
                                    <strong>
                                        {question.question_id?.name}
                                    </strong>
                                </td>
                                {itmeAnswers[question?.id]?.map(item => {
                                    return item
                                })}
                            </tr>
                        </>)
                    })
                    itmes.push(
                        <table style={{ marginTop: '20px' }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <h4>{section.section_id?.title}</h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )
                    itmes.push(
                        <>
                            <table width="100%" style={{ border: '1px solid black', borderCollapse: 'collapse', marginTop: '20px' }}>
                                <tbody>
                                    {lines?.map(item => {
                                        return item
                                    })}
                                </tbody>
                            </table>
                        </>
                    )
                } else {
                    itmes.push(
                        <table style={{ marginTop: '20px' }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <h4>{section.section_id?.title}</h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )
                    await section.questions.map(async (question, index) => {
                        itmes.push(<>
                            <>
                                <table width={'100%'}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>
                                                    {question.question_id?.name}
                                                </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>
                                                    {question.answer}
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        </>)
                    })
                }
                if (section.section_id?.comment_title) {
                    itmes.push(
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>
                                            {section.section_id?.comment_title}
                                        </strong>
                                    </td>
                                    <td>
                                        {section.comment}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )
                }
            }

        })
        let html_st = <div style={{ fontSize: '20px', margin: '20px', pageBreakBefore: 'recto' }}>
            <table width="100%">
                <tbody>
                    <tr>
                        {selectedLang === 'en' &&
                            <td align="left">
                                <h6>
                                    EXIT INTERVIEW QUESTIONNAIRE
                                </h6>
                            </td>
                        }
                        {selectedLang === 'ar' &&
                            <td align="right">
                                <h6>
                                    استبيان مقابلة الخروج
                                </h6>
                            </td>
                        }
                        {selectedLang === 'fr' &&
                            <td align="left">
                                <h6>
                                    QUESTIONNAIRE D’ENTREVUE DE DÉPART
                                </h6>
                            </td>
                        }

                    </tr>
                </tbody>
            </table>
            <hr />
            <table width="100%">
                <tbody>
                    <tr>
                        <td width="50%">
                            <table width="100%">
                                <tr>
                                    <td width="50%">Name</td>
                                    <td width="50%">{exitInterveiw.azadea_employee_name}</td>
                                </tr>
                                <tr>
                                    <td width="50%">Department</td>
                                    <td width="50%">{exitInterveiw.branch_id?.name}</td>
                                </tr>
                                <tr>
                                    <td width="50%">Position</td>
                                    <td width="50%">{exitInterveiw.job_id?.name}</td>
                                </tr>
                            </table>
                        </td>
                        <td width="50%">
                            <table width="100%">
                                <tr>
                                    <td width="50%">Start date</td>
                                    <td width="50%">{exitInterveiw.employee_id?.start_date ? moment(exitInterveiw.employee_id?.start_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT) : ''}</td>
                                </tr>
                                <tr>
                                    <td width="50%">Last working day</td>
                                    <td width="50%">{exitInterveiw.employee_id?.actual_end_employee_date ? moment(exitInterveiw.employee_id?.actual_end_employee_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT) : ''}</td>
                                </tr>
                                <tr>
                                    <td width="50%">Manager’s Name</td>
                                    <td width="50%">{exitInterveiw.employee_id?.parent_id?.name}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr />

            {itmes?.map(item => {
                return item
            })}

            <table width="100%" style={{ marginTop: '20px' }}>
                <tbody>
                    <tr>
                        {selectedLang === 'en' &&
                            <td align="left">
                                <input type="checkbox" defaultChecked={true} width={'25px'} height={'25px'} />&nbsp;&nbsp;By submitting this exit interview questionnaire, I, {exitInterveiw.employee_id.name}, acknowledge and confirm the accuracy of all information provided herein. Furthermore, I understand that the information provided may be used by the company for internal purposes, including but not limited to improving organizational practices and addressing concerns related to employee satisfaction and retention.
                            </td>
                        }
                        {selectedLang === 'ar' &&
                            <td align="right">
                                <input type="checkbox" defaultChecked={true} width={'25px'} height={'25px'} />&nbsp;&nbsp;By submitting this exit interview questionnaire, I, {exitInterveiw.employee_id.name}, acknowledge and confirm the accuracy of all information provided herein. Furthermore, I understand that the information provided may be used by the company for internal purposes, including but not limited to improving organizational practices and addressing concerns related to employee satisfaction and retention.
                            </td>
                        }
                        {selectedLang === 'fr' &&
                            <td align="left">
                                <input type="checkbox" defaultChecked={true} width={'25px'} height={'25px'} />&nbsp;&nbsp;By submitting this exit interview questionnaire, I, {exitInterveiw.employee_id.name}, acknowledge and confirm the accuracy of all information provided herein. Furthermore, I understand that the information provided may be used by the company for internal purposes, including but not limited to improving organizational practices and addressing concerns related to employee satisfaction and retention.
                            </td>
                        }
                    </tr>
                </tbody>
            </table>
            <table width="100%" style={{ marginTop: '20px' }}>
                <tbody>
                    <tr>
                        {selectedLang === 'en' &&
                            <td align="left">
                                <input type="checkbox" defaultChecked={exitInterveiw.office_submit_approve === true} width={'25px'} height={'25px'} />&nbsp;&nbsp;Upon submission of this exit interview questionnaire, I express my willingness to further discuss the information provided above with a member of the HR department or the Executive Board in a confidential meeting. Please select your choice:
                            </td>
                        }
                        {selectedLang === 'ar' &&
                            <td align="right">
                                <input type="checkbox" defaultChecked={exitInterveiw.office_submit_approve === true} width={'25px'} height={'25px'} />&nbsp;&nbsp;Upon submission of this exit interview questionnaire, I express my willingness to further discuss the information provided above with a member of the HR department or the Executive Board in a confidential meeting. Please select your choice:
                            </td>
                        }
                        {selectedLang === 'fr' &&
                            <td align="left">
                                <input type="checkbox" defaultChecked={exitInterveiw.office_submit_approve === true} width={'25px'} height={'25px'} />&nbsp;&nbsp;Upon submission of this exit interview questionnaire, I express my willingness to further discuss the information provided above with a member of the HR department or the Executive Board in a confidential meeting. Please select your choice:
                            </td>
                        }
                    </tr>
                </tbody>
            </table>


            <table width="100%" >
                <tbody>
                    <tr>
                        {selectedLang === 'en' &&
                            <td align="left">
                                <input type="checkbox" width={'25px'} height={'25px'} defaultChecked={exitInterveiw.hr_department === true} />&nbsp;&nbsp;HR department
                            </td>
                        }
                        {selectedLang === 'ar' &&
                            <td align="right">
                                <input type="checkbox" width={'25px'} height={'25px'} defaultChecked={exitInterveiw.hr_department === true} />&nbsp;&nbsp;HR department
                            </td>
                        }
                        {selectedLang === 'fr' &&
                            <td align="left">
                                <input type="checkbox" width={'25px'} height={'25px'} defaultChecked={exitInterveiw.hr_department === true} />&nbsp;&nbsp;HR department
                            </td>
                        }
                    </tr>
                    {backOfficeSesction &&

                        <tr>
                            {selectedLang === 'en' &&
                                <td align="left">
                                    <input type="checkbox" width={'25px'} height={'25px'} defaultChecked={exitInterveiw.executive_member === true} />&nbsp;&nbsp;Executive Member&nbsp;&nbsp;<u>{exitInterveiw.executive_employee_name}</u>
                                </td>
                            }
                            {selectedLang === 'ar' &&
                                <td align="right">
                                    <input type="checkbox" width={'25px'} height={'25px'} defaultChecked={exitInterveiw.executive_member === true} />&nbsp;&nbsp;Executive Member&nbsp;&nbsp;<u>{exitInterveiw.executive_employee_name}</u>
                                </td>
                            }
                            {selectedLang === 'fr' &&
                                <td align="left">
                                    <input type="checkbox" width={'25px'} height={'25px'} defaultChecked={exitInterveiw.executive_member === true} />&nbsp;&nbsp;Executive Member&nbsp;&nbsp;<u>{exitInterveiw.executive_employee_name}</u>
                                </td>
                            }
                        </tr>
                    }
                </tbody>
            </table>
            <table width="100%" style={{ marginTop: '20px' }}>
                <tbody>
                    <tr>
                        {selectedLang === 'en' &&
                            <td align="left">
                                I understand that this meeting will be arranged at my request and will be conducted in accordance with the company's policies on confidentiality and data protection.
                            </td>
                        }
                        {selectedLang === 'ar' &&
                            <td align="right">
                                I understand that this meeting will be arranged at my request and will be conducted in accordance with the company's policies on confidentiality and data protection.
                            </td>
                        }
                        {selectedLang === 'fr' &&
                            <td align="left">
                                I understand that this meeting will be arranged at my request and will be conducted in accordance with the company's policies on confidentiality and data protection.
                            </td>
                        }
                    </tr>
                </tbody>
            </table>


        </div>
        return html_st
    }

    const downloadFilePDF = (responseType: ResponseType = 'blob') => {
        const file_id = formRef?.current?.values.interview_file_id
        if (file_id) {
            axios
                .get(`${API_URL}/attachments/${file_id.id}`, {
                    responseType,
                })
                .then((response) => {
                    let file_name = response.headers['content-disposition']
                    file_name = file_name.replace('attachment; filename=', '')
                    file_name = file_name.replaceAll('\"', '')
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', file_name)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                })
                .catch((error) => {
                    console.error('Download error:', error)
                })
        }
    }

    const generatePDFFile = () => {
        if (id) {
            document.body.classList.add('page-loading')
            GetExitInterViewRequestById(parseInt(id)).then(async (data: ExitInterViewRequest) => {

                let html_obj;
                html_obj = generate_report(data)
                html_obj.then(async html_obj => {
                    if (html_obj) {
                        const options = {
                            // method: 'open', // 'save' or 'open'
                            resolution: Resolution.HIGH,
                            page: {
                                margin: Margin.SMALL,
                                format: 'A4',
                                //   orientation: 'landscape',
                            },
                            canvas: {
                                //   mimeType: 'image/png',
                                qualityRatio: 1,
                            },
                            overrides: {
                                pdf: { compress: false },
                                canvas: { useCORS: true },
                            },
                        };
                        const parentElement = document.createElement('div');
                        const element =
                            <div>
                                <button onClick={() => generatePDF(getTargetElement, options)} id="export_data_pdf">Generate PDF</button>
                                <div id="content-id">
                                    {html_obj}
                                </div>
                            </div>
                        ReactDOM.render(element, parentElement);
                        document.body.appendChild(parentElement)
                        const inputElement = document.querySelector('#export_data_pdf') as HTMLInputElement
                        inputElement.click()
                        document.body.removeChild(parentElement)
                        document.body.classList.remove('page-loading')
                        document.body.classList.remove('page-loading')
                    }
                })
            })
        }
    }

    const openAttachWizard = () => {
        const inputElement = document.querySelector('#file') as HTMLInputElement
        inputElement.click()
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        document.body.classList.add('page-loading')
        if (files) {
            await Array.from(files).forEach((file, index) => {
                const fileType = file.type;
                if (AZKHelper.ALLOWED_DOC_TYPES.includes(fileType)) {
                    const reader = new FileReader();
                    reader.onloadend = async () => {
                        const arrayBuffer = reader.result;
                        let datas = ''
                        if (arrayBuffer instanceof ArrayBuffer) {
                            let binary = '';
                            const uint8Array = new Uint8Array(arrayBuffer);
                            const len = uint8Array.byteLength;
                            for (let i = 0; i < len; i++) {
                                binary += String.fromCharCode(uint8Array[i]);
                            }
                            datas = window.btoa(binary)
                        } else if (arrayBuffer) {
                            datas = arrayBuffer.split(',')[1]
                        }
                        const formData = new FormData();
                        formData.append('res_model', MODEL_NAME);
                        if (id) formData.append('res_id', '' + parseInt(id));
                        formData.append('name', 'FS' + file.name);
                        formData.append('type', "binary");
                        formData.append('datas', datas);
                        axios.post(`${API_URL}/attachments/upload`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                            if (id && response && response.data)
                                axios.put(`${API_URL}/${MODEL_NAME}/${parseInt(id)}/upload_pdf`, { 'attachment_id': response.data['id'] }).then(response => {
                                    // updateForm(parseInt(id))
                                    document.body.classList.remove('page-loading')
                                    Swal.fire({
                                        position: "top-right",
                                        title: 'Success!',
                                        text: 'The file had been uploaded!',
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                                        toast: true,
                                        iconColor: 'white',
                                        customClass: {
                                            popup: 'colored-toast'
                                        },
                                        timerProgressBar: true
                                    })
                                }, err => {
                                    document.body.classList.remove('page-loading')
                                })
                            return response.data.id
                        }).catch(function (error) {
                            console.log(error)
                            document.body.classList.remove('page-loading')
                            return error;
                        })
                    }
                    reader.readAsArrayBuffer(file);
                } else {
                    document.body.classList.remove('page-loading')
                    Swal.fire({
                        position: "top-right",
                        title: 'Sorry!',
                        text: 'Please check you attachments, allowed file types (' + AZKHelper.ALLOWED_DOC_TYPE + ')!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                };
            })
        }
    }

    const saveApproverDetails = async (values: ExitInterViewRequest) => {
        setFormSubmited(true)
        formRef.current?.setSubmitting(true)
        formRef.current?.validateForm()
        if (values.did_not_sit_with_hr !== undefined && values.did_not_sit_with_hr !== null && values.regrettable!== undefined && values.regrettable!== null && isNotEmpty(values.id) && values.id) {
            document.body.classList.add('page-loading')
            axios.put(`${API_URL}/${MODEL_NAME}/${values.id}/save_approver_values`, {
                'regrettable': values.regrettable,
                'did_not_sit_with_hr': values.did_not_sit_with_hr,
                'comments': values.comments,
            }).then(response => {
                document.body.classList.remove('page-loading')
                Swal.fire({
                    position: "top-right",
                    title: 'Success!',
                    text: 'The exit interview had been saved!',
                    icon: "success",
                    showConfirmButton: false,
                    timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }, err => {
                document.body.classList.remove('page-loading')
            })
        }
    }

    const pobulateFormData = async (data?: ExitInterViewRequest) => {
        const buttons = [{}]
        buttons.pop();
        if (data) {
            if (data?.lang == 'ar') setDirection('rtl')
            else setDirection('ltr')
            setselectedLang(data.lang)
            if (data.type_id && data.type_id.back_office === true) {
                setBackOffice(true)
            }
            if (data.can_review) {
                buttons.push({ title: "Approve", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }), method: "validate_tier", confirmMsg: "Approve Transaction, Confirm?", cssClass: "btn btn-light btn-light-success", icon: "fa-thumbs-up" })
            }
            // if (data.can_review) {
            //     buttons.push({ title: "Decline", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.DECLINE' }), method: "reject_tier", confirmMsg: "Decline Transaction, Confirm?", cssClass: "btn btn-light btn-light-danger", icon: "fa-times" })
            // }
            // if (data.can_review) {
            //     buttons.push({ title: "Review", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.REVIEW' }), method: "restart_validation", confirmMsg: "Send Transaction back to draft, Confirm?", cssClass: "btn btn-light btn-light-warning", icon: "fa-refresh" })
            // }
            if (data.interview_file_id) {
                buttons.push({ title: "Download PDF", cssClass: "btn btn-light btn-light-warning", icon: "fa-download", callback: downloadFilePDF })
            }
            if (data.can_review && !data.interview_file_id) {
                buttons.push({ title: "Upload PDF", confirmMsg: undefined, cssClass: "btn btn-light btn-light-warning", icon: "fa-upload", callback: openAttachWizard })
            }
            if (data.can_review && !data.interview_file_id) {
                buttons.push({ title: "Generate PDF", cssClass: "btn btn-light btn-light-warning", icon: "fa-download", callback: generatePDFFile })
            }
            formRef.current?.setValues({
                id: data.id,
                employee_id: data.employee_id?.id,
                az_country_id: data.az_country_id?.id,
                az_company_id: data.az_company_id?.id,
                branch_id: data.branch_id?.id,
                brand_id: data.brand_id?.id,
                grade_id: data.grade_id?.id,
                section_id: data.section_id?.id,
                job_id: data.job_id?.id,
                time_set: data.time_set,
                sections: data.sections,
                type_id: data.type_id?.id,
                submit_approve: data.submit_approve,
                office_submit_approve: data.office_submit_approve,
                executive_employee_name: data.executive_employee_name,
                hr_department: data.hr_department,
                executive_member: data.executive_member,
                regrettable: data.regrettable,
                did_not_sit_with_hr: data.did_not_sit_with_hr,
                comments: data.comments,
                interview_file_id: data.interview_file_id,
            })
            setAllowedButttons(buttons)
            if (data.regrettable === true){
                setRegrettable({ value: 'yes', label: 'Yes' })
            } else if (data.regrettable === false){
                setRegrettable({ value: 'no', label: 'No' })
            }
            if (data.did_not_sit_with_hr === true){
                isSetWithHR({ value: 'no', label: 'No' })
            } else if (data.did_not_sit_with_hr === false){
                isSetWithHR({ value: 'yes', label: 'Yes' })
            }
        }
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/self-service/exit-interview/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "top-right",
                        title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/self-service/exit-interview/`, { state: {}, replace: true });
                Swal.fire({
                    position: "top-right",
                    title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    useEffect(() => {
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        setObjectId(undefined)
        setCanValidate(false)
        handleEmployeeChange(undefined, undefined)
        if (id) {
            setObjectId(parseInt(id))
            checkPermission('write');
            document.body.classList.add('page-loading')
            GetExitInterViewRequestById(parseInt(id)).then(((data: ExitInterViewRequest) => {
                handleEmployeeChange(data?.employee_id?.id, data)
                if (data?.x_state && data?.x_state_view) {
                    setTransactionState(data?.x_state)
                    setTransactionStateName(data?.x_state_view)
                }
                if (data?.can_write && data?.can_write !== null) {
                    setCanEdit(data?.can_write)
                }
                setTransactionClosed(data.validated || data.rejected ? true : false)
                setCanValidate(data?.can_review ? true : false)
            }))
        } else {
            checkPermission('create');
            formRef.current?.setFieldValue('employee_id', undefined)
            handleEmployeeChange(undefined, undefined)
            setCanEdit(true)
            document.body.classList.remove('page-loading')
        }
    }, [id])


    const cancel = () => {
        navigate(`/self-service/exit-interview/`, { state: {}, replace: true });
    }

    const deleteTransaction = () => {
        Swal.fire({
            position: "center",
            title: 'Delete',
            text: 'Delete Transaction confirm?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed && id) {
                DeleteExitInterViewRequest(parseInt(id)).then(() => {
                    cancel()
                    Swal.fire({
                        position: "top-right",
                        title: 'Deleted!',
                        text: 'Your transaction had been deleted!',
                        icon: "warning",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                })
            } else if (result.isDenied) {
                Swal.fire('Transaction was not deleted', '', 'info')
            }
        })
    }


    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        document.body.classList.remove('page-loading')
        navigate(`/self-service/exit-interview/`, { state: {}, replace: true })
        Swal.fire({
            position: "top-right",
            title: 'Success!',
            text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
            icon: "success",
            showConfirmButton: false,
            timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
            toast: true,
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            timerProgressBar: true
        })
    }

    const onSubmitHandler = async (values: ExitInterViewRequest, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            document.body.classList.add('page-loading')
            if (isNotEmpty(values.id)) {
                await UpdateExitInterViewRequest(values).then(data => {
                    if (id) handleResultOfsubmit(parseInt(id), true)
                })
            }
        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }

    const [formSubmited, setFormSubmited] = useState<boolean>(false);
    const [set_with_hr, isSetWithHR] = useState<any>('');
    const [regrettable, setRegrettable] = useState<any>('');
    const loadTypeOptions = async (search, loadedOptions) => {
        const options: any[] = []
        options.push({ value: '', label: '' })
        options.push({ value: 'yes', label: 'Yes' })
        options.push({ value: 'no', label: 'No' })
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };

    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransfaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}

                                    {allowedButttons && allowedButttons.length > 0 && id &&
                                        <AzkActionButtons
                                            actionUrl={`${API_URL}/${MODEL_NAME}/${parseInt(id)}/`}
                                            thenNavigate="/self-service/exit-interview/"
                                            divStyle="card py-2 mb-3"
                                            buttons={allowedButttons} />}

                                    <Form onSubmit={handleSubmit} noValidate>
                                        <fieldset disabled={!canEdit || readOnly}>
                                            {!id && (
                                                <Row className="card m-0 py-2 mb-3">
                                                    <Col>
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label>
                                                                <h4 className="px-3 m-0 custom-employee-header justify-content-start">
                                                                    <i className="fa fa-magnifying-glass" aria-hidden="true"></i>{intl.formatMessage({ id: 'TRANSACTION.EMPLOYEE' })}
                                                                </h4>
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="azk_temp"
                                                                modelName="hr.employee"
                                                                valueField="id"
                                                                titleField="display_name_fr"
                                                                dataDomain=''
                                                                allowEmpty={true}
                                                                fieldName='employee_id'
                                                                onChange={(value) => {
                                                                    setFieldValue('employee_id', parseInt(value))
                                                                    handleEmployeeChange(value, undefined)
                                                                }}
                                                                defaultValue={values.employee_id}
                                                                disabled={!canEdit}
                                                                searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='employee_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </fieldset>
                                        {employee && (
                                            <>
                                                <EmployeeDetail employee={employee} />
                                                <input
                                                    accept={AZKHelper.ALLOWED_DOC_TYPE}
                                                    disabled={transactionClosed}
                                                    type='file'
                                                    id='file'
                                                    className="d-none"
                                                    onChange={handleFileChange} />
                                                <Row className="m-0 card">
                                                    <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start">
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>EXIT INTERVIEW QUESTIONNAIRE</h4>
                                                    <fieldset disabled={!canEdit || readOnly}>

                                                        {values?.sections && <>
                                                            <Row className="m-0 my-2">
                                                                <FieldArray name="sections">
                                                                    {({ push, remove }) => (
                                                                        <>
                                                                            {
                                                                                values?.sections?.map(
                                                                                    (section, index) => (
                                                                                        <>
                                                                                            {values.sections && values.sections[index] &&
                                                                                                <Row className="m-0 my-2 py-2 p-0">
                                                                                                    <Col lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-between">
                                                                                                        <h6>
                                                                                                            {section?.section_id?.title}
                                                                                                        </h6>
                                                                                                    </Col>
                                                                                                    <FieldArray name="questions">
                                                                                                        {({ push, remove }) => (
                                                                                                            <>
                                                                                                                {(!section?.section_id?.is_table_content || section?.section_id?.is_table_content === null) &&
                                                                                                                    <>
                                                                                                                        {section?.questions?.map(
                                                                                                                            (question, questionIndex) => (
                                                                                                                                <>
                                                                                                                                    <Row className="m-0 my-2 py-2 p-0">
                                                                                                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                            <h6 className="text-small">
                                                                                                                                                <small>{question?.question_id?.name}</small>
                                                                                                                                            </h6>
                                                                                                                                        </Col>
                                                                                                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                            {question.answer_code !== question.answer && question.answer_code !== null &&
                                                                                                                                                <Form.Control
                                                                                                                                                    required={true}
                                                                                                                                                    className={`p-1 rounded-0`}
                                                                                                                                                    type='text'
                                                                                                                                                    disabled={true}
                                                                                                                                                    value={question.answer_code + ' / ' + question.answer}
                                                                                                                                                />
                                                                                                                                            }
                                                                                                                                            {question.answer_code === question.answer || question.answer_code === null &&
                                                                                                                                                <Form.Control
                                                                                                                                                    required={true}
                                                                                                                                                    className={`p-1 rounded-0`}
                                                                                                                                                    type='text'
                                                                                                                                                    disabled={true}
                                                                                                                                                    value={question.answer}
                                                                                                                                                />
                                                                                                                                            }
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                    {question.question_id.comment_title &&
                                                                                                                                        <Row className="m-0 pt-0 pb-2 p-0 row">
                                                                                                                                            <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                                <h6 className="text-small">
                                                                                                                                                    <small>{question?.question_id?.comment_title}</small>
                                                                                                                                                </h6>
                                                                                                                                            </Col>
                                                                                                                                            <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                                <Form.Control
                                                                                                                                                    required={true}
                                                                                                                                                    className={`p-1 rounded-0`}
                                                                                                                                                    type='text'
                                                                                                                                                    disabled={true}
                                                                                                                                                    value={question.comment}
                                                                                                                                                />
                                                                                                                                            </Col>
                                                                                                                                        </Row>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        )
                                                                                                                        }
                                                                                                                        {section && section.section_id && section?.section_id?.comment_title &&
                                                                                                                            <Row className="m-0 pt-0 pb-2 p-0 row">
                                                                                                                                <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                    <h6 className="text-small">
                                                                                                                                        <small>{section?.section_id?.comment_title}</small>
                                                                                                                                    </h6>
                                                                                                                                </Col>
                                                                                                                                <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                    <Form.Control
                                                                                                                                        required={true}
                                                                                                                                        className={`p-1 rounded-0`}
                                                                                                                                        type='text'
                                                                                                                                        disabled={true}
                                                                                                                                        value={section.comment}
                                                                                                                                    />
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                }

                                                                                                                {(section?.section_id?.is_table_content && section?.section_id?.is_table_content === true) &&
                                                                                                                    <>
                                                                                                                        <Row className="m-0 my-2 py-2 p-0">
                                                                                                                            <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                <table className="table-bordered w-100">
                                                                                                                                    <tbody>
                                                                                                                                        {section?.questions?.map(
                                                                                                                                            (question, questionIndex) => (
                                                                                                                                                <>
                                                                                                                                                    <tr>
                                                                                                                                                        <td className="w-70 px-1">
                                                                                                                                                            <h6 className="text-small">
                                                                                                                                                                <small>{question?.question_id.name}</small>
                                                                                                                                                            </h6>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="w-30">
                                                                                                                                                            {(question.answer_code !== question.answer && question.answer_code !== null) &&
                                                                                                                                                                <Form.Control
                                                                                                                                                                    required={true}
                                                                                                                                                                    className={`p-1 rounded-0`}
                                                                                                                                                                    type='text'
                                                                                                                                                                    disabled={true}
                                                                                                                                                                    value={question.answer_code + ' / ' + question.answer}
                                                                                                                                                                />
                                                                                                                                                            }
                                                                                                                                                            {(question.answer_code === question.answer || question.answer_code === null) &&
                                                                                                                                                                <Form.Control
                                                                                                                                                                    required={true}
                                                                                                                                                                    className={`p-1 rounded-0`}
                                                                                                                                                                    type='text'
                                                                                                                                                                    disabled={true}
                                                                                                                                                                    value={question.answer}
                                                                                                                                                                />
                                                                                                                                                            }
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                </>
                                                                                                                                            )
                                                                                                                                        )
                                                                                                                                        }

                                                                                                                                        {section?.section_id?.comment_title &&
                                                                                                                                            <tr>
                                                                                                                                                <td colSpan={2}>
                                                                                                                                                    <Row className="m-0 my-1">
                                                                                                                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                                            <h6 className="text-small">
                                                                                                                                                                <small>{section?.section_id?.comment_title}</small>
                                                                                                                                                            </h6>
                                                                                                                                                        </Col>
                                                                                                                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                                            <Form.Control
                                                                                                                                                                required={true}
                                                                                                                                                                className={`p-1 rounded-0`}
                                                                                                                                                                type='text'
                                                                                                                                                                disabled={true}
                                                                                                                                                                value={section.comment}
                                                                                                                                                            />
                                                                                                                                                        </Col>
                                                                                                                                                    </Row>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        }
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            </Col>
                                                                                                                        </Row>
                                                                                                                    </>
                                                                                                                }
                                                                                                            </>
                                                                                                        )}
                                                                                                    </FieldArray>
                                                                                                </Row>
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                )
                                                                            }
                                                                        </>
                                                                    )}
                                                                </FieldArray>
                                                            </Row>
                                                        </>
                                                        }
                                                        <Row className="m-0 my-2">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Check className="py-1"
                                                                    key={`submit_approve`}
                                                                    label={`By submitting this exit interview questionnaire, I, ${employee.name}, acknowledge and confirm the accuracy of all information provided herein. Furthermore, I understand that the information provided may be used by the company for internal purposes, including but not limited to improving organizational practices and addressing concerns related to employee satisfaction and retention.`}
                                                                    name={`submit_approve`}
                                                                    disabled={true}
                                                                    id={`submit_approve`}
                                                                    defaultChecked={values.submit_approve}
                                                                />

                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='submit_approve' />
                                                                </div>
                                                            </Form.Group>
                                                        </Row>
                                                        <Row className="m-0 my-2">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Check className="py-1"
                                                                    key={`office_submit_approve`}
                                                                    label="Upon submission of this exit interview questionnaire, I express my willingness to further discuss the information provided above with a member of the HR department or the Executive Board in a confidential meeting. Please select your choice:"
                                                                    name={`office_submit_approve`}
                                                                    disabled={true}
                                                                    id={`office_submit_approve`}
                                                                    defaultChecked={values.office_submit_approve}
                                                                />

                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='office_submit_approve' />
                                                                </div>
                                                            </Form.Group>
                                                            <div className="col-12 px-6">
                                                                <Form.Group className='col-12'>
                                                                    <Form.Label>
                                                                        <Form.Check className="py-1"
                                                                            key={`hr_department`}
                                                                            label="HR department"
                                                                            name={`hr_department`}
                                                                            id={`hr_department`}
                                                                            disabled={true}
                                                                            defaultChecked={values.hr_department}
                                                                        />

                                                                    </Form.Label>
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='hr_department' />
                                                                    </div>
                                                                </Form.Group>
                                                                {backOffice &&
                                                                    <Form.Group className='col-12'>
                                                                        <Form.Label>
                                                                            <Form.Check className="py-1"
                                                                                key={`executive_member`}
                                                                                label="Executive member"
                                                                                name={`executive_member`}
                                                                                id={`executive_member`}
                                                                                defaultChecked={values.executive_member}
                                                                            />
                                                                        </Form.Label>
                                                                        {values.executive_member === true &&
                                                                            <Form.Control
                                                                                required={true}
                                                                                className={`p-1 rounded-0`}
                                                                                type='text'
                                                                                disabled={true}
                                                                                value={values.executive_employee_name}
                                                                            />
                                                                        }
                                                                    </Form.Group>
                                                                }
                                                            </div>
                                                        </Row>
                                                        <Row className="m-0 my-2">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label>
                                                                    I understand that this meeting will be arranged at my request and will be conducted in accordance with the company's policies on confidentiality and data protection.
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Row>
                                                    </fieldset>
                                                    <Row className="m-0 my-2">
                                                        <Row className="m-0 my-2">
                                                            <Row className="m-0 my-2">
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label>
                                                                        Sit With HR
                                                                            {/* <Form.Check
                                                                                label="Did Not Sit With HR"
                                                                                onChange={(value) => {
                                                                                    values.did_not_sit_with_hr = value.target.checked
                                                                                }}
                                                                                defaultChecked={values.did_not_sit_with_hr === true}
                                                                            /> */}
                                                                        </Form.Label>

                                                                        <AsyncPaginate className='rounded-0 col-12'
                                                                            placeholder="Select an option"
                                                                            loadOptions={loadTypeOptions}
                                                                            onChange={(value) => {
                                                                                isSetWithHR(value)
                                                                                if(value && value['value']  === 'yes'){
                                                                                    values.did_not_sit_with_hr = false
                                                                                } else if(value && value['value']  === 'no'){
                                                                                    values.did_not_sit_with_hr = true
                                                                                } else {
                                                                                    values.did_not_sit_with_hr = undefined
                                                                                }
                                                                                formRef?.current?.validateForm()
                                                                            }}
                                                                            defaultValue={set_with_hr}
                                                                            value={set_with_hr}
                                                                            id={`did_not_sit_with_hr`}
                                                                        />
                                                                        <div className='text-danger'>
                                                                            {formSubmited && (!set_with_hr || !set_with_hr.value) && "Field is required" }
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label>
                                                                            Regrettable Employee
                                                                            {/* <Form.Check
                                                                                label="Regrettable Employee"
                                                                                onChange={(value) => {
                                                                                    values.regrettable = value.target.checked
                                                                                }}
                                                                                defaultChecked={values.regrettable === true}
                                                                            /> */}
                                                                        </Form.Label>
                                                                        
                                                                        <AsyncPaginate className='rounded-0 col-12'
                                                                            placeholder="Select an option"
                                                                            loadOptions={loadTypeOptions}
                                                                            onChange={(value) => {
                                                                                setRegrettable(value)
                                                                                if(value && value['value']  === 'yes'){
                                                                                    values.regrettable = false
                                                                                } else if(value && value['value']  === 'no'){
                                                                                    values.regrettable = true
                                                                                } else {
                                                                                    values.regrettable = undefined
                                                                                }
                                                                                formRef?.current?.validateForm()
                                                                            }}
                                                                            defaultValue={regrettable}
                                                                            value={regrettable}
                                                                            id={`regrettable`}
                                                                        />
                                                                        <div className='text-danger'>
                                                                            {formSubmited && (!regrettable || !regrettable.value) && "Field is required" }
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg="12" md="12" sm="12" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label>
                                                                            Comments
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            className={`p-1 rounded-0`}
                                                                            type='text'
                                                                            onChange={(value) => {
                                                                                values.comments = value.target.value
                                                                            }}
                                                                            value={values.comments}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Row>
                                                    </Row>
                                                    {/* begin::Actions */}
                                                    <div className='py-5 d-flex justify-content-end'>
                                                        <button
                                                            title="Save"
                                                            type='reset'
                                                            onClick={() => saveApproverDetails(values)}
                                                            className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                            data-kt-users-modal-action='Save'
                                                        >
                                                            <i className="fas fa-check fs-2"></i>
                                                            <span className='indicator-label'>Save</span>

                                                        </button>
                                                        {!canValidate &&
                                                            <button
                                                                title="Discard & back"
                                                                type='reset'
                                                                onClick={() => cancel()}
                                                                className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <KTIcon iconName='exit-left' className='fs-2' />
                                                                <span className='indicator-label'>Discard</span>

                                                            </button>
                                                        }
                                                    </div>
                                                    {/* end::Actions */}
                                                </Row>
                                            </>)}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
                <Col md='12' lg='3' xl='3'>
                    {id && (<AzkStatusRibbon transactionId={parseInt(id)} modelName={MODEL_NAME}
                        current={transactionState} currentName={transactionStateName} />
                    )}
                    {employee && <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={objectId} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />}
                </Col>
            </Row>
        </>
    )
}

export default TransactionViewform;
